import { removeCookie } from './cookie';
import router from '@/router';
export function getUserDetails() {
  return localStorage.getItem('user');
}

export function setUserDetails(userDetail) {
  localStorage.setItem('user', userDetail);
}

export function signOut() {
  localStorage.removeItem('user');
  removeCookie('token');
  // window.location.reload();
  // router.push({name: 'login'});
  window.location.href = router.resolve({ name: 'login' }).href;
}
