import { createStore } from 'vuex';
import VuexPersistance from 'vuex-persist';

import * as cards from './modules/cards';

const store = createStore({
  modules: {
    cards,
  },
  state: {
    companyData: {},
    companyName: '',
    userId: '',
  },
  mutations: {
    setCompanyData(state, orders) {
      state.companyData = orders;
    },
    setCompanyName(state, companyName) {
      state.companyName = companyName;
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
  },
  plugins: [
    new VuexPersistance({
      storage: window.localStorage,
    }).plugin,
  ],
});

export default store;
