<template>
  <div>
    <!-- class="table-responsive custom-table-responsive" -->
    <div id="infinite-list">
      <table :class="tableClass" class="table">
        <!-- set caption and visible using slot -->
        <slot name="caption" v-if="caption">
          <caption>{{ caption }}</caption>
        </slot>
        <thead>
          <slot name="columns">
            <tr>
              <th v-for="(title, index) in titles" :key="index">
                <!-- check if last item in array -->
                {{ title }}
              </th>
              <th v-if="actions.length > 0">
                <div class="col text-center px-0">{{ buttonActions }}</div>
              </th>
            </tr>
          </slot>
        </thead>
        <tbody style="hoverflow-x: auto;">
          <tr v-if="!tableData.length && noData && defaultNoData && !isFetchLoading">
            <td :colspan="columns.length + 1" class="text-center text-muted custom-table-default-no-data"
              style="height: 50;">{{ defaultNoData }}</td>
          </tr>
          <tr v-if="!tableData.length && noData && !defaultNoData && !loading && !isFetchLoading">
            <td :colspan="columns.length + 1" class="text-center text-danger" style="height: 200px;">{{ noData }}</td>
          </tr>
          <tr v-for="(item, index) in  tableData " :key="index" class="px-0">
            <slot scope="row" :row="item">
              <td v-for="(column, indexCol) in  columns " :key="indexCol" class="py-0">
                <a class="custom-link-field" @click="$emit('clickLinkField', { document: itemValue(item, column) })"
                  v-if="column?.type === 'linkField'">
                  {{ itemValue(item, column) }}

                  <!-- <i class="fa fa-external-link" aria-hidden="true"></i> -->
                </a>
                <span v-else>{{ itemValue(item, column) }}</span>
              </td>
              <td class="px-0 py-2">
                <div class="col-12 px-0 justify-content-center mx-auto row ">
                  <button class="py-0 px-1 mx-1 btn btn-sm" v-for="( action, index ) in  actions " :key="index"
                    type="button" v-bind="action" @click="emitDinamicActionButton(item, action)">
                    <i :class="action.icon"></i> {{ action.label }}
                  </button>
                </div>
              </td>
            </slot>
          </tr>
        </tbody>
      </table>
      <transition name="fade">
        <div v-if="loading || isFetchLoading" class="custom-table-loading">
          <label class="text-dark text-center">Aguarde...</label>
          <i class="fa fa-spinner custom-table-loading-icon" aria-hidden="true"></i>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'base-table',
  data() {
    return {
      tableData: [],
      loading: false,
      countIndex: 0
    };
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: 'Array com os nomes das colunas da tabela'
    },
    titles: {
      type: Array,
      default: () => [],
      description: 'Títulos das colunas da tabela'
    },
    data: {
      type: Array,
      default: () => [],
      description: 'Array de dados para a tabela'
    },
    actions: {
      type: Array,
      default: () => [],
      description: 'Array de botões que serão exibidos no final de cada linha'
    },
    caption: {
      type: String,
      default: '',
      description: 'Título da tabela'
    },
    tableClass: {
      type: String,
      default: 'table-striped table-hover',
      description: 'Classe da tabela'
    },
    buttonActions: {
      type: String,
      default: 'Ações',
      description: 'Título do botão de ações'
    },
    noData: {
      type: [String, Boolean],
      default: 'Nenhum dado encontrado',
      description: 'Mensagem exibida quando não houver dados para exibir'
    },
    defaultNoData: {
      type: [String, Boolean],
      default: 'Tabela Vazia',
      description: 'Mensagem exibida quando ainda não existem dados para exibir'
    },
    isFetchLoading: {
      type: [Boolean],
      default: false,
      description: 'Exibe o loading dentro da tabela enquanto aguarda a resposta da promise'
    }
  },
  watch: {
    data: function () {
      // vericar apos cada alteração se um valor foi removido e atualizar o index
      this.countIndex = 0;
      this.tableData = [];
      this.loadMore();
    },
    tableData: function () {
      // this.$emit('isListEmpty', (this.tableData || []).length === 0)
      if (this.loading) return;
      this.emitDinamicActionButton((this.tableData || []).length === 0, { name: 'isListEmpty' });
    }
  },
  methods: {
    hasValue(item, column) {
      return item[column] != null;
    },
    itemValue(item, column) {
      let separatedItem;
      if (typeof column === 'object') {
        separatedItem = column.name.split('.');
      } else { separatedItem = column.split('.'); }
      // pick up item inside object anguanto exist separatedItem
      let value = item;

      value = separatedItem.reduce((acc, curr) => {
        if (acc) {
          return acc[curr];
        }
      }, value);
      if (typeof column === 'object' && column.validate) {
        // check if has a validate function
        if (typeof column.validate !== 'function') {
          throw new Error('The validation object inside the column does not contain a valid function please check the Array and try again');
        } else return column.validate(value, item);
      } else return value;
    },
    emitDinamicActionButton(item, action) {
      this.$emit(action.name, item);
      // insert child events in parent and take in another vuejs component
      this.$parent.$emit(action.name, item);
    },
    loadMore: function () {
      if (this.countIndex < this.data.length) { this.loading = true; }
      setTimeout(() => {
        for (let i = 0; i < this.data.length; i++) {
          if (this.countIndex < this.data.length) {
            this.tableData.push(this.data[this.countIndex]);
            this.countIndex++;
          }
        }
        this.loading = false;
      }, 250);
    }
  },
  mounted() {
    const listElem = document.querySelector('#infinite-list');
    listElem.addEventListener('scroll', () => {
      // get scroll if scroll is at the bottom
      if (listElem.scrollTop + listElem.clientHeight >= listElem.scrollHeight) {
        this.loadMore();
      }
    });
    this.loadMore();
  }
};
</script>
<style lang="scss" scoped>
.custom-table-responsive {
  height: 65vh;
  overflow-y: auto;
}

.custom-table-default-no-data {
  background-color: #f9f9f9;
}

.custom-table-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 100px;
  background-color: #f9f9f9;
}

.custom-table-loading-icon {
  color: gray;
  font-size: 32px;
  animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.custom-link-field {
  display: flex;
  min-width: max-content;
  flex-wrap: nowrap;
  gap: 8px;
  color: #198ae3;
  cursor: pointer;
}
</style>
