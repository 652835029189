<template>
  <validation
    :messageError="!!errorMessage ? ($attrs.messageError || errorMessage) : ''"
    v-bind="$attrs"
    :label="label"
    id="base-radio-">
    <div v-if="options.length === 0" class="form-check form-check-inline d-flex">
      <input
        v-bind="attrs"
        v-on="listeners"
        v-model="value"
        type="radio"
        class="form-check-input"/>
      <label v-if="label" class="form-check-label mx-0">
        {{ label }}
        <span
          v-if="/required/.test($attrs.validate) || (typeof $attrs.validate == 'object' && $attrs.validate.required)"
          class="text-danger">
          *
        </span>
      </label>
    </div>
    <div
      class="form-check form-check-inline m-0 justify-content-center h-50"
      :class="{
        'd-flex': inline
      }"
      v-else>
      <div
        v-for="(option, index) in options" :key="index"
        class="form-check form-check-inline d-flex my-0"
        :class="{
          'mr-0': index == options.length - 1 && Object.keys($attrs).indexOf('inline') == -1,
          'justify-content-center': Object.keys($attrs).indexOf('inline') == -1
        }">
        <input
          v-bind="attrs"
          v-on="listeners"
          :name="option.name"
          :value="option.value"
          v-model="value"
          type="radio"
          class="form-check-input"/>
        <label
          v-if="label"
          class="form-check-label mx-0">
          {{option.label}}
        </label>
      </div>
    </div>
  </validation>
</template>

<script setup>
/* eslint-disable */
import Validation from '@/components/base-components/Validation.vue';
import { useField, useForm } from 'vee-validate';
import { ref, computed, defineProps, useAttrs, onMounted, onBeforeMount } from 'vue';

const $attrs = useAttrs();
const selectedValue = ref(null);
const customName = ref('');

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  options: {
    type: Array,
    default: () => []
  },
  modelValue: {
    default: null
  },
  messageError: {
    type: String,
    default: null,
    description: 'Message error for input'
  },
  inline: {
    type: Boolean,
    default: true
  }
});

const listeners = computed(() => ({
  ...$attrs,
  input: onInput
}));

/* eslint-disable no-unused-vars */
const attrs = computed(() => {
  const {
    validate,
    class:
    _class,
    style,
    ...attrs
  } = {...$attrs};
  return attrs;
});

const customValidate = ($attrs.validate || '').replace('required', 'required_radio');

const {
  errorMessage,
  handleChange,
  value
  // validate
} = useField(($attrs.fieldName || $attrs.name || $attrs.label), customValidate, {
  initialValue: props.modelValue || null,
  modelPropName: customName.value,
  syncVModel: true,
  valueProp: 'modelValue',
  validateOnValueUpdate: true,
  type: 'checkbox'
});

const form = useForm();

form.setValues(($attrs.fieldName || $attrs.name || $attrs.label), props.modelValue || null, customName.value);


onMounted(() => {
  selectedValue.value = props.modelValue;
});

function onInput(event) {
  const { value } = event.target;
  if (['true', 'false'].includes(value)) {
    emit('update:modelValue', value === 'true');
  } else {
    emit('update:modelValue', value);
  }
}

onBeforeMount(() => {
  customName.value = generateCustomName();
});

// generate custom name with 16 characters
function generateCustomName () {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < 16; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
</script>

<style lang="scss" scoped>
input[type="radio"] {
  width: 1.5rem;
}
</style>
