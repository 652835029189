<template>
  <form @submit.prevent="onSubmit">
    <base-loading :show="loading" />
    <fieldset class="row px-0 mx-0">
      <h3 class="mb-5 col-12">Alterar Senha</h3>
      <base-input
        class="col-12"
        label="Senha Atual"
        fieldName="senha atual"
        validate="required|min:3"
        placeholder="Digite sua senha"
        v-model="currentPassword"
      />

      <base-input
        class="col-12"
        label="Nova senha"
        fieldName="nova senha"
        validate="required|min:3"
        placeholder="Digite sua nova senha"
        v-model="newPassword"
      />

      <base-input
        class="col-12"
        label="Confirmar senha"
        fieldName="confirmar senha"
        validate="required|min:3"
        placeholder="Confirme sua nova senha"
        v-model="confirmPassword"
      />
    </fieldset>

    <div class="d-flex justify-content-end mt-4 mr-3 mb-3">
      <button
        class="btn btn-outline-danger mx-1"
        type="button"
        @click="$emit('hide-modal')"
      >
        Cancelar
      </button>
      <button class="btn btn-outline-success mx-1" type="submit">
        Alterar
      </button>
    </div>
  </form>
</template>

<script setup>
// eslint-disable-next-line no-unused-vars
import { ref, defineProps, inject, watch, onBeforeMount } from 'vue';
const $swal = inject('$swal');
import $http from '@/plugins/axios';

// const emit = defineEmits(['hide-modal']);
const loading = ref(false);

const additiveData = ref({
  name: '',
  file: null,
  signed: false,
  date: null,
});

const currentPassword = ref('');
const newPassword = ref('');
const confirmPassword = ref('');
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  userId: {
    type: String,
    default: null,
    required: true,
  },
});

watch(
  () => additiveData.value,
  (value) => {
    if (!value.name) {
      const { name, document } = props.company || {};
      if (name && document) {
        additiveData.value.name = `${document.replace(
          /\D/g,
          ''
        )}_${`0${new Date().getDate()}`.slice(-2)}-${`0${
          new Date().getMonth() + 1
        }`.slice(-2)}-${new Date().getFullYear()}_${
          name ? name.split(' ')[0] : 'S/N'
        }`;
      }
    }
  }
);

async function onSubmit() {
  await updatePassword();
}

async function updatePassword() {
  if (newPassword.value === confirmPassword.value) {
    loading.value = true;

    await $http
      .patch(
        `/user/updatePasswordAsync?userId=${props.userId}&OldPassword=${currentPassword.value}&NewPassword=${newPassword.value}`
      )
      .then(() => {
        $swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Senha alterada com sucesso!',
        });
        // emit('hide-modal');
      })
      .catch((err) => {
        $swal.fire({
          icon: 'error',
          title: 'Ops!',
          text:
            err.response.data[0].message ||
            'Houve um erro ao processar a requisição, tente novamente mais tarde',
          showConfirmButton: true,
        });
      })
      .finally(() => {
        loading.value = false;
      });
  }
}
</script>

<style scoped></style>
