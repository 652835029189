<template>
  <validation
    :messageError="!!errorMessage ? ($attrs.messageError || errorMessage) : ''"
    v-bind="$attrs"
    id="base-input-">
    <div>
      <input
        v-if="!isCustomMask && !mask"
        v-on="listeners"
        v-bind="attrs"
        v-model="value"
        class="form-control"/>
      <input
        v-else-if="!isCustomMask && mask"
        v-on="listeners"
        v-bind="attrs"
        v-mask="mask"
        v-model="value"
        class="form-control"/>
      <the-mask
        v-else
        v-on="listeners"
        v-bind="attrs"
        :mask="mask"
        v-model="value"
        :tokens="customMask()"
        class="form-control"/>
    </div>
  </validation>
</template>

<script setup>
/* eslint-disable */
import validation from './Validation.vue';
// eslint-disable-next-line no-unused-vars
import { useField, useForm } from 'vee-validate';

// eslint-disable-next-line no-unused-vars
import { ref, computed, defineProps, useAttrs, watch, defineEmits, defineComponent, onBeforeMount } from 'vue';

const $attrs = useAttrs();
const customName = ref('');

const isCustomMask = ref(false);

const props = defineProps({
  customMask: {
    type: Function,
    default: null,
    description: 'Custom mask function'
  },
  mask: {
    type: [String, Array],
    default: null,
    description: 'Mask to be used'
  },
  modelValue: {
    type: [String, Number, Boolean],
    default: null
  },
  messageError: {
    type: String,
    default: null
  }
});

const {
  errorMessage,
  handleChange,
  value
  // validate
} = useField(($attrs.fieldName || $attrs.name || $attrs.label), $attrs.validate, {
  initialValue: props.modelValue || null,
  modelPropName: customName.value,
  syncVModel: true,
  valueProp: 'modelValue',
  validateOnValueUpdate: true
});

const form = useForm();

form.setValues(($attrs.fieldName || $attrs.name || $attrs.label), props.modelValue || null, customName.value);

// watch(() => props.modelValue, async () => {
//   console.log(await validate(props.modelValue));
// });

const listeners = computed(() => {
  // if (!errorMessage.value)
  //   return {
  //     ...$attrs,
  //     input: (e) => handleChange(e, false),
  //     blur: handleChange,
  //     change: handleChange
  //   };
  return {
    ...$attrs,
    input: handleChange,
    blur: handleChange,
    change: handleChange
  };
});

const attrs = computed(() => {
  return {
    ...$attrs,
    class: '',
    style: ''
  };
});

watch(() => props.customMask, (val) => {
  isCustomMask.value = (!!val && typeof val === 'function');
});

defineComponent({
  name: 'BaseInput',
  inheritAttrs: false
});

onBeforeMount(() => {
  customName.value = generateCustomName();
});

// generate custom name with 16 characters
function generateCustomName () {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < 16; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
</script>

<style>

</style>
