import router from '@/router';
import store from '@/store';
import 'bootstrap/dist/js/bootstrap.min.js';
import { VueSweetalert2 } from '@/plugins/sweetalert2';
import http from '@/plugins/axios';
import VueTheMask from '@/plugins/vue-the-mask';

const services = {
  router,
  store,
};

export default {
  install(Vue) {
    Vue.use(VueSweetalert2);
    Vue.config.globalProperties.$http = http;
    for (const key in services) Vue.use(services[key]);
    Vue.use(VueTheMask);
  },
};
