<template>
  <div class="base-loading" v-if="loading">
    <div class="col-12 row p-0 m-0">
      <div class="ring mx-auto" ></div>
      <div class="col-12 mt-5 text-center text-white">
        <div class="container">
          <p class="One">Console OnlyPay</p>
        </div>
        <!-- <div class="container">
          <p class="Two">Soluções em meios de pagamentos</p>
        </div> -->
      </div>
    </div>
    <!-- set text slogan with animation -->
  </div>
</template>

<script>
export default {
  name: 'BaseLoading',
  data () {
    return {
      loading: false
    };
  },
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    show (val) {
      this.disableAllActionUser();
      if (!val) {
        setTimeout(() => {
          this.loading = false;
          this.enableAllActionUser();
        }, 1000);
      } else this.loading = val;
      this.$emit('change', val);
    }
  },
  methods: {
    disableAllActionUser () {
      document.body.style.pointerEvents = 'none';
      document.body.style.userSelect = 'none';
    },
    enableAllActionUser () {
      document.body.style.pointerEvents = 'auto';
      document.body.style.userSelect = 'auto';
    }
  },
  beforeMount () {
    this.loading = this.show;
  }
};
</script>

<style lang="scss">
// loading
.base-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: progress;
  .ring {
    border: 20px solid rgb(211, 211, 211);
    // position: absolute;
    // left: 45%;
    border-radius: 50%;
    border-top: 20px solid var(--primary);
    width: 120px;
    height: 120px;
    animation: spin 2.5s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .One {
    font-size: 2.5rem;
    font-weight: bold;
    animation: fadeIn 5s infinite;
    transition: all 2.5s ease-in-out;
  }
  .Two {
    font-size: 1.5rem;
    font-weight: bold;
    animation: fadeIn 5s infinite;
    transition: all 2.5s ease-in-out;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0.1;
    }
    50% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.05;
    }
  }
}
</style>
