<template>
  <validation
    :messageError="!!errorMessage ? ($attrs.messageError || errorMessage) : ''"
    v-bind="$attrs"
    class="base-switch"
    id="base-input-">
    <div class="col-12 custom-control custom-switch justify-content-center">
      <input
        :value="model[modelValue].value"
        @input="$emit('update:modelValue', $event.target.checked)"
        type="checkbox"
        :id="'base-switch-' + dynamicId"
        class="custom-control-input"/>
      <label class="custom-control-label p-0 m-0" :for="'base-switch-' + dynamicId" style="user-select: none;">{{ model[modelValue].label }}</label>
    </div>
  </validation>
</template>

<script setup>
import Validation from './Validation.vue';
//eslint-disable-next-line
import { ref, defineEmits, defineProps, watch, onBeforeMount, inject, onMounted } from 'vue';

// const emit = defineEmits(['update:modelValue']);

//eslint-disable-next-line
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  errorMessage: {
    type: String,
    default: ''
  },
  options: {
    type: Array,
    default: () => {
      const value = [
        { label: '', value: true },
        { label: '', value: false }
      ];
      return value;
    },
    validator: (value) => {
      // check if value is an array is not empty
      return value.every((item) => {
        if (typeof item !== 'object') {
          throw new Error('options must be an array of objects');
        }
        if (item.value === null || item.value === undefined) {
          throw new Error('options must have a value property');
        }
        if (typeof item.value !== 'boolean') {
          throw new Error('options value property must be a boolean');
        }
        return true;
      });
    }
  }
});

const dynamicId = generateDynamicId();
const model = ref({});

function generateDynamicId () {
  return Math.random().toString(36).substr(2, 9);
}

// convert options to object and set to model
onBeforeMount(() => {
  const { options } = props;
  const value = {};
  options.forEach((item) => {
    value[item.value] = item;
  });
  model.value = value;
});

onMounted(() => {
  // set modelValue in the input
  const inputSwitch = document.getElementById(`base-switch-${dynamicId}`);
  inputSwitch.checked = props.modelValue;
});
</script>

<style lang="scss" scoped>
.base-switch {
  .custom-control-label::before {
    top: 0;
  }
  .custom-switch .custom-control-label::before {
    top: 0;
  }
  .custom-switch .custom-control-label::after {
    top: 2px;
  }
}

</style>
