<template>
  <validation
    :messageError="!!errorMessage ? ($attrs.messageError || errorMessage) : messageError ?? ''"
    v-bind="$attrs"
    id="base-password-">
    <div>
      <div class="input-group">
        <input
          v-bind="attrs"
          v-on="listeners"
          autocomplete="new-password"
          :value="modelValue"
          :type="showPassword ? 'text' : 'password'"
          class="form-control mx-0"/>
          <span
            href="javascript:void(0)"
            class="input-group-addon mx-0 px-0 row align-items-center justify-content-center btn btn-secondary"
            style="border-radius: 0 2px 2px 0; width: 44px; height: 44.22px;"
            @click="showPassword = !showPassword; ">
            <div class="text-center">
              <i class="fa fa-eye-slash" aria-hidden="true" v-if="!showPassword"></i>
              <i class="fa fa-eye" aria-hidden="true" v-else></i>
            </div>
          </span>
      </div>
    </div>
  </validation>
</template>

<script>
/* eslint-disable */
import Validation from './Validation.vue';
import { useField, useForm } from 'vee-validate';
export default {
  inheritAttrs: false,
  name: 'BasePassword',
  data () {
    return {
      isCustomMask: false,
      showPassword: false
    };
  },
  components: {
    Validation
  },
  props: {
    customMask: {
      type: Function,
      default: null,
      description: 'Custom mask function'
    },
    mask: {
      type: [String, Array],
      default: null,
      description: 'Mask to be used'
    },
    modelValue: {
      type: String,
      default: null
    },
    messageError: {
      type: String,
      default: null
    }
  },
  computed: {
    listeners () {
      return {
        // TODO: ajustar listeners
        // ...this.$listeners,
        input: this.onInput,
        blur: this.handleBlur,
        ...this.attrs
      };
    },
    attrs () {
      /* eslint-disable no-unused-vars */
      const {
        validate,
        class: _class,
        style,
        ...attrs
      } = this.$attrs;
      return attrs;
    },
  },
  watch: {
    customMask (val) {
      this.isCustomMask = (!!val && typeof val === 'function');
    }
  },
  methods: {
    onInput (event) {
      // this.handleChange(event);
      this.$emit('update:modelValue', (event && event.target && event.target.value) || null);
    }
  },
  setup (props, {attrs}) {
    const {
      errorMessage,
      handleBlur,
      handleChange
    } = useField((attrs.fieldName || attrs.name || attrs.label), attrs.validate, {
      initialValue: props.modelValue
    });

    const form = useForm();

    form.setValues((attrs.fieldName || attrs.name || attrs.label), props.modelValue || null);
    
    return {
      errorMessage,
      handleBlur,
      handleChange
    };
  },
  beforeMount () {
    this.isCustomMask = (!!this.customMask && typeof this.customMask === 'function');
  }
};
</script>

<style>

</style>
