<template>
    <validation
      :messageError="!!errorMessage ? ($attrs.messageError || errorMessage) : ''"
      v-bind="$attrs"
      id="base-select-">
    <select
      v-bind="attrs"
      v-on="listeners"
      :id="'selectOption' + dynamicId"
      :value="modelValue"
      class="custom-select text-muted">
      <!-- create option default selected null-->
      <option
        value=""
        v-if="showDefaultOption"
        disabled
        selected>
        Selecione
      </option>
      <option
        v-for="(option, index) in options"
        :key="index"
        :value="option.value">
        {{option.label}}
      </option>
    </select>
  </validation>
</template>

<script>
/* eslint-disable */
import Validation from './Validation.vue';
import { useField, useForm } from 'vee-validate';
export default {
  name: 'BaseSelect',
  inheritAttrs: false,
  data () {
    return {
      error: null,
      dynamicId: null,
      fieldError: {
        isValid: true,
        message: ''
      }
    };
  },
  components: {
    Validation
  },
  props: {
    value: {
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    showDefaultOption: {
      type: Boolean,
      default: true
    },
    modelValue: {
      default: null
    }
  },
  computed: {
    listeners () {
      return {
        // TODO: ajustar listeners
        // ...this.$listeners,
        input: this.onInput,
        blur: this.handleBlur,
        ...this.attrs
      };
    },
    attrs () {
      /* eslint-disable no-unused-vars */
      const {
        validate,
        class: _class,
        style,
        ...attrs
      } = this.$attrs;
      
      return attrs;
    }
  },
  watch: {},
  methods: {
    generateDynamicId () {
      return Math.random().toString(36).substr(2, 9);
    },
    onInput (event) {
      // this.handleChange(event);
      this.$emit('update:modelValue', (event && event.target && event.target.value) || null);
    }
  },
  beforeMount () {
    this.dynamicId = this.generateDynamicId();
  },
  setup (props, {attrs}) {
    const {
      errorMessage,
      handleBlur,
      handleChange
    } = useField((attrs.fieldName || attrs.name || attrs.label), attrs.validate, {
      initialValue: props.modelValue
    });

    const form = useForm();

    form.setValues((attrs.fieldName || attrs.name || attrs.label), props.modelValue || null);
    
    return {
      errorMessage,
      handleBlur,
      handleChange
    };
  },
  mounted () {
    const select = document.getElementById(`selectOption${  this.dynamicId}`);
    select.addEventListener('focus', () => {
      select.classList.remove('text-muted');
    });
    select.addEventListener('blur', () => {
      if (select.value) select.classList.remove('text-muted');
      else select.classList.add('text-muted');
    });
  }
};
</script>

<style lang="scss">
  .custom-select {
    height: 45px;
  }
</style>
