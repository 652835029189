<template>
  <validation
  :messageError="!!errorMessage ? ($attrs.messageError || errorMessage) : ''"
    v-bind="$attrs"
    id="base-date-">
    <div>
      <flat-picker
        autocomplete="false"
        aria-describedby="addon-right addon-left"
        v-on="listeners"
        v-bind="attrs"
        :config="{
          allowInput: false,
          altFormat: altFormat,
          dateFormat: dateFormat,
          altInput: true,
          mode: 'single',
          locale: langData[locale],
          dropdown: 'static',
          ...config
        }"
        :placeholder="placeholder"
        :class="{
          'form-control': !$attrs.config || !$attrs.config.altInput,
          // 'col-11': true,
          // 'pl-4': true
        }"
        v-model="value" />
    </div>
  </validation>
</template>

<script setup>
/* eslint-disable */
import flatPicker from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/material_blue.css';
import lang from 'flatpickr/dist/l10n';
// eslint-disable-next-line no-unused-vars
import { useField, useForm } from 'vee-validate';
import Validation from './Validation.vue';
// eslint-disable-next-line no-unused-vars
import { ref, computed, defineProps, useAttrs, watch, defineEmits, defineComponent, onBeforeMount } from 'vue';

defineComponent({
  name: 'BaseDate',
  inheritAttrs: false,
});

const langData = ref(lang);
const customName = ref(null);

const $attrs = useAttrs();

// const emit = defineEmits();

const props = defineProps({
  modelValue: {
    default: null
  },
  locale: {
    type: String,
    default: (navigator.language || 'pt').slice(0, 2)
  },
  config: {
    type: Object,
    default: () => {}
  },
  dateFormat: {
    type: String,
    default: 'Y-m-d'
  },
  altFormat: {
    type: String,
    default: 'd/m/Y'
  },
  placeholder: {
    type: String,
    default: 'Selecione uma data'
  }
});

const listeners = computed(() => {
  return {
    ...$attrs
  };
});

const attrs = computed(() => {
  return {
    ...$attrs,
    class: '',
    style: ''
  };
});

const {
  errorMessage,
  handleChange,
  value
  // validate
} = useField(($attrs.fieldName || $attrs.name || $attrs.label), $attrs.validate, {
  initialValue: props.modelValue || null,
  modelPropName: customName.value,
  syncVModel: true,
  valueProp: 'modelValue',
  validateOnValueUpdate: true
});

const form = useForm();

form.setValues(($attrs.fieldName || $attrs.name || $attrs.label), props.modelValue || null, customName.value);

onBeforeMount(() => {
  // model.value = props.modelValue;
  customName.value = generateCustomName();
});

watch(value, (newValue) => {
  // emit('update:modelValue', newValue);
  handleChange(newValue);
  form.setValues(($attrs.fieldName || $attrs.name || $attrs.label), newValue, customName.value);
});

watch(()=> props.modelValue, (newValue) => {
  value.value = newValue;
});

// generate custom name with 16 characters
function generateCustomName () {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < 16; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
</script>

<style lang="scss">
.form-control:disabled,
.form-control[readonly] {
  background-color: #fff !important;
}
</style>
