import veeValidate from '@/plugins/vee-validate';

import VueSidebarMenu from '@/plugins/vue-sidebar-menu';

import BaseCheckbox from '@/components/base-components/BaseCheckbox.vue';
import BaseDate from '@/components/base-components/BaseDate.vue';
import BaseFile from '@/components/base-components/BaseFile.vue';
import BaseForm from '@/components/base-components/BaseForm.vue';
import BaseInput from '@/components/base-components/BaseInput.vue';
import BaseLoading from '@/components/base-components/BaseLoading.vue';
import BaseModal from '@/components/base-components/BaseModal.vue';
import BaseMoney from '@/components/base-components/BaseMoney.vue';
import BasePassword from '@/components/base-components/BasePassword.vue';
import BaseRadio from '@/components/base-components/BaseRadio.vue';
import BaseSelect from '@/components/base-components/BaseSelect.vue';
import BaseStep from '@/components/base-components/BaseStep.vue';
import BaseSwitch from '@/components/base-components/BaseSwitch.vue';
import BaseTable from '@/components/base-components/BaseTable.vue';
import BaseTextarea from '@/components/base-components/BaseTextarea.vue';
import JsonExcel from 'vue-json-excel3';
import Multiselect from 'vue-multiselect';

export default {
  install(Vue) {
    // importing veeValidate as a plugin
    for (const key in veeValidate) Vue.component(key, veeValidate[key]);
    // importing vue-sidebar-menu as a plugin
    for (const key in VueSidebarMenu) Vue.component(key, VueSidebarMenu[key]);
    Vue.component('base-form', BaseForm);
    Vue.component('base-input', BaseInput);
    Vue.component('base-password', BasePassword);
    Vue.component('base-checkbox', BaseCheckbox);
    Vue.component('base-date', BaseDate);
    Vue.component('base-file', BaseFile);
    Vue.component('base-loading', BaseLoading);
    Vue.component('base-modal', BaseModal);
    Vue.component('base-money', BaseMoney);
    Vue.component('base-multiselect', Multiselect);
    Vue.component('base-radio', BaseRadio);
    Vue.component('base-select', BaseSelect);
    Vue.component('base-step', BaseStep);
    Vue.component('base-switch', BaseSwitch);
    Vue.component('base-table', BaseTable);
    Vue.component('base-textarea', BaseTextarea);
    Vue.component('base-download-excel', JsonExcel);
  },
};
