import { createApp } from 'vue';
import App from './App.vue';

// import components
import globalComponents from './plugins/globalComponents';
// import services
import services from './plugins/services';

const app = createApp(App);

// use components
app.use(globalComponents);

// use services
app.use(services);

app.mount('#app');
