<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>


<style>
@import "../node_modules/mdi/css/materialdesignicons.min.css";
@import "../node_modules/flag-icon-css/css/flag-icon.min.css";
@import "../node_modules/font-awesome/css/font-awesome.min.css";
@import "../node_modules/simple-line-icons/css/simple-line-icons.css";
@import "../node_modules/ti-icons/css/themify-icons.css";
@import "../node_modules/fullcalendar/dist/fullcalendar.min.css";
@import "../node_modules/codemirror/lib/codemirror.css";
/* @import "../node_modules/vue-snotify/styles/material.css"; */
</style>

<style lang="scss">
@import "./assets/scss/style.scss";
.vue-btn {
  .spinner {
    height: 20px !important;
    width: 20px !important;
  }
}
.btn-block {
  width: 100% !important;
}
</style>
