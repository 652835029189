<template>
  <div class="form-group" v-bind="attrsValidation" :ref="'provider' + dynamicId" :id="dynamicId">
    <Field :rules="validate" :name="fieldName || label" v-slot="{ handleChange, handleBlur, errors }">
      <label :for="$attrs.id" v-if="label">
        {{ label }}
        <span v-if="/required/.test(JSON.stringify(validate))" class="text-danger">
          *
        </span>
      </label>
      <div class="custom-file">
        <input v-bind="attrs" v-on="listeners" @change="{ onChange($event); handleChange($event); }"
          @blur="handleBlur($event);" type="file" class="custom-file-input stretched-link" style="cursor: pointer;">
        <label class="custom-file-label mb-0 text-left ml-0" :class="{
          'text-muted': !file.name
        }" style="line-height: 1.5;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;">
          {{ file.name || 'Nenhum arquivo selecionado' }}
        </label>
      </div>
      <!-- create loading -->
      <div v-if="progress" class="progress mt-1">
        <div :style="{ width: progress + '%' }" class="progress-bar progress-bar-striped progress-bar-animated bg-dark">
          <strong>{{ progress + '%' }}</strong></div>
      </div>
      <div v-else>
        <small v-if="obs && !errors[0]" class="form-text text-primary text-right mt-1"><span>*</span>Obs: {{ obs }}</small>
        <span class="invalid-feedback mt-1" :class="{ 'd-block': errors[0] }">
          <strong>{{ messageError || errors[0] }}</strong>
        </span>
      </div>
    </Field>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
// import * as validation from '@/helpers/validation'
import { defineComponent, ref, defineProps, computed, useAttrs, defineEmits, onBeforeMount } from 'vue';
import { useField } from 'vee-validate';

const $attrs = useAttrs();

const dynamicId = ref(null);
const file = ref({});
const fileBase64 = ref(null);
const progress = ref(0);
const validateObject = ref({});

const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  messageError: {
    type: String,
    default: null
  },
  modelValue: {
    default: null
  },
  fieldName: {
    type: String,
    default: null,
    description: 'Name your field to use in validation'
  },
  obs: {
    type: String,
    default: null,
    description: 'Observation'
  },
  validate: {
    type: [String, Object],
    default: null,
    description: 'Validate your field'
  }
});

const attrsValidation = computed(() => {
  /* eslint-disable no-unused-vars */
  const {
    validate,
    messageError,
    modelValue,
    accept,
    fieldName,
    label,
    obs,
    id,
    name,
    placeholder,
    modelModifiers,
    rules,
    value,
    ...attrs
  } = props;
  return attrs;
});

const validate = computed(() => {
  // transform validate in object
  const valueAttrs = props.validate;
  if (typeof valueAttrs === 'string') {
    const validate = {};
    valueAttrs.split('|').forEach((item) => {
      const [key, value] = item.split(':');
      validate[key] = value || true;
      if (typeof validate[key] === 'string') {
        validate[key].split(',').forEach((itemArray) => {
          if (!Array.isArray(validate[key])) validate[key] = [];
          if (itemArray) validate[key].push(itemArray);
        });
      }
      validate[key] = validate[key].length > 1 ? validate[key] : validate[key][0] || true;
    });
    return validate;
  }
  return valueAttrs;
});

const listeners = computed(() => {
  return {
    ...$attrs['on']
  };
});

const attrs = computed(() => {
  /* eslint-disable no-unused-vars */
  const {
    validate,
    class: _class,
    style,
    ...attrs
  } = $attrs;

  return attrs;
});

function generateDynamicId() {
  return Math.random().toString(36).substr(2, 9);
}

async function parseToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.onerror = (e) => {
      reject(e);
    };
    reader.readAsDataURL(file);
  });
}
const emit = defineEmits();

async function onChange(event) {
  file.value = event.target.files[0] || {};
  // carregar arquivo e mostrar porcentagem no loading
  if (file.value && file.value.size) {
    progress.value = 1;
    const reader = new FileReader();

    reader.onprogress = (e) => {
      if (e.lengthComputable) progress.value = Math.round((e.loaded * 100) / e.total);
    };
    reader.readAsDataURL(file.value);
  }

  setTimeout(() => {
    if (progress.value === 100) progress.value = 0;
  }, 1500);

  emit('base64', await parseToBase64(file.value));
  emit('update:modelValue', file.value);
}

function checkExistDisabledKey() {
  const obj = $attrs;
  const disabled = Object.keys(obj).indexOf('disabled') !== -1;
  // if exist disabled key in object return disabled value
  if (disabled) return !!(obj.disabled == null || obj.disabled === true);
  else return false;
}

function checkIsValidFileValue() {
  const file = props.modelValue || {};

  if (typeof file === 'string') return !!file;
  return file.value && file.value.name && file.value.size;
}

onBeforeMount(() => {
  dynamicId.value = `base-file-${generateDynamicId()}`;
  if (checkIsValidFileValue()) {
    const reader = new FileReader();
    reader.onload = (e) => {
      fileBase64.value = e.target.result;
    };
    reader.readAsDataURL(props.modelValue);
    file.value = props.modelValue;
  }
});
</script>

<style lang="scss" scoped>
.custom-file {
  height: 44.22px;
  display: flex;
  flex-direction: column;
}
label.custom-file {
  display: flex;
  flex-direction: column;
}
label.custom-file-label {
  height: 44.22px;
  border-radius: 2px;
  padding: 12px;
}

label.custom-file-label::after {
  content: "Escolher arquivo";
  height: 44.22px;
  border-bottom-left-radius: 1px;
  border-top-right-radius: 1px;
  padding: 12px;
}
input.custom-file-input { 
  height: 44.22px;
}

input.custom-file-input::after {
  height: 44.22px;
}
</style>
