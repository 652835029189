import axios from 'axios';
import { getCookie, removeCookie } from '@/helpers/cookie';
import swal from 'sweetalert2';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

instance.interceptors.request.use(
  (config) => {
    // TODO: ADD token to all requests
    config.headers.Authorization = `Bearer ${getCookie('token')}`;

    // set haader Paramter token

    if ((config.url || '').match(/^\//))
      config.headers['token'] = getCookie('token');
    else delete config.headers['token'];

    // Prevent caching by adding Cache-Control header
    config.headers['Cache-Control'] = 'no-cache';
    config.headers['Pragma'] = 'no-cache';
    config.headers['Expires'] = '0';

    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

instance.interceptors.response.use(
  (res) => {
    const { data } = res;
    if (!data) return false;
    if (data.code >= 400 && data.code <= 500) {
      // console.error('Error status code: ', data.code);
    }
    return res;
  },
  (err) => {
    if (!err.response) {
      // console.error('Problema com o servidor. Tente novamente.');
      swal.fire({
        title: 'Oops!',
        html: 'Erro ao tentar se conectar com o servidor. <br/> Por favor, verifique se você está conectado a internet e se está conectado a VPN.',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return Promise.reject({...err, response: { data: { message: 'Erro ao tentar se conectar com o servidor. Por favor, verifique se você está conectado a internet e se está conectado a VPN.' }}});
    }
    if (err.response.status === 401) {
      removeCookie('token');
      removeCookie('tenantId');
      removeCookie('userId');
      // window.location.reload();
      // console.error('Redirect because user is not logged in');
    }
    return Promise.reject(err);
  }
);

export default instance;
