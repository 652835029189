function isValidCNPJ(doc) {
  const cnpj = doc.replace(/[^\d]+/g, '');
  if (cnpj.length !== 14) return false;

  let cnpjLenght = cnpj.length - 2;
  let cnpjNumbers = cnpj.substring(0, cnpjLenght);
  const digits = cnpj.substring(cnpjLenght);
  let soma = 0;
  let pos = cnpjLenght - 7;
  for (let i = cnpjLenght; i >= 1; i--) {
    soma += cnpjNumbers.charAt(cnpjLenght - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  // eslint-disable-next-line
  if (resultado != digits.charAt(0)) return false
  cnpjLenght = cnpjLenght + 1;
  cnpjNumbers = cnpj.substring(0, cnpjLenght);
  soma = 0;
  pos = cnpjLenght - 7;
  for (let i = cnpjLenght; i >= 1; i--) {
    soma += cnpjNumbers.charAt(cnpjLenght - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  // eslint-disable-next-line
  if (resultado != digits.charAt(1)) return false
  return true;
}

function isValidCPF(value) {
  const cpf = value.replace(/\D/g, '');
  if (cpf.toString().length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
    return false;
  }
  let result = true;
  // eslint-disable-next-line
  var element = [9, 10];
  element.forEach((j) => {
    let sum = 0;
    let r;
    cpf.split(/(?=)/).splice(0, j).forEach((e, i) => {
      sum += parseInt(e, 10) * ((j + 2) - (i + 1));
    });
    r = sum % 11;
    r = (r < 2) ? 0 : 11 - r;
    if (r !== parseInt(cpf.substring(j, j + 1), 10)) {
      result = false;
    }
  });
  return result;
}

// function isNullOrUndefined(value) {
//   return value === null || value === undefined;
// }

// function isEmptyArray(arr) {
//   return Array.isArray(arr) && arr.length === 0;
// }

const rules = {
  document: (document) => {
    if ((document?.length > 0 && document?.length < 14)) { return false; }

    if ((document?.length === 14)) {
      const doc = document?.replace(/[^0-9]/g, '');
      return isValidCPF(doc);
    }

    if ((document?.length > 14 && document?.length < 18)) { return false; }

    if ((document?.length === 18)) {
      const doc = document?.replace(/[^0-9]/g, '');
      return isValidCNPJ(doc);
    }

    return true;

    // if (!document || document.length === 0) { return false; }

    // const doc = document.replace(/[^0-9]/g, '');
    // return (isValidCPF(doc) || isValidCNPJ(doc));
  },
  cnpj: (cnpj) => {
    if ((cnpj?.length > 0 && cnpj?.length < 18)) { return false; }

    if ((cnpj?.length === 18)) {
      const doc = cnpj?.replace(/[^0-9]/g, '');
      return isValidCNPJ(doc);
    }

    return true;

    // if (!cnpj || cnpj.length === 0) { return false; }

    // const doc = cnpj.replace(/[^0-9]/g, '');
    // return isValidCNPJ(doc);
  },
  cpf: (cpf) => {
    if ((cpf?.length > 0 && cpf?.length < 14)) { return false; }

    if ((cpf?.length === 14)) {
      const doc = cpf.replace(/[^0-9]/g, '');
      return isValidCPF(doc);
    }
    return true;

    // if (!cpf || cpf.length === 0) { return false; }

    // const doc = cpf.replace(/[^0-9]/g, '');
    // return isValidCPF(doc);
  },
  phone: (phone) => {
    if ((phone?.length > 0 && phone?.length < 14)) { return false; }

    if ((phone?.length === 14 || phone?.length === 15)) {
      const valid = (/00000000|11111111|22222222|33333333|44444444|55555555|66666666|77777777|88888888|99999999/)
        .test((phone || '').replace(/\D/g, ''));
      if (valid) return false;
      return /^(\+55\s?)?(\([0-9]{3}\))(\s?\d{4}-?)(\d{4})$|^(\+55\s?)?(\([0-9]{2}\))(\s?[9]{1}\s?)(\d{4}-?)(\d{4})$/
        .test(phone);
    }

    return true;

    // const valid = (/00000000|11111111|22222222|33333333|44444444|55555555|66666666|77777777|88888888|99999999/)
    //   .test((phone || '').replace(/\D/g, ''));
    // if (valid) return false;
    // return /^(\+55\s?)?([1-9]{2}|[2-9]{1}[1-9]{1}|\([1-9]{2}\)|\([2-9]{1}[1-9]{1}\))?(\s?\d{4}-?)(\d{4})$|^(\+55\s?)?([1-9]{2}|[2-9]{1}[1-9]{1}|\([1-9]{2}\)|\([2-9]{1}[1-9]{1}\))?(\s?[9]{1}\s?)(\d{4}-?)(\d{4})$/.test(phone);
  },
  url: (url) => {
    return /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g.test(url);
  },
  accepted: (value) => {
    return ['yes', 'on', 1, '1', true].includes(value);
  },
  'not_accepted': (value) => {
    return !['yes', 'on', 1, '1', true].includes(value);
  },
  cep: (cep) => {
    return /^[0-9]{5}-[0-9]{3}$/g.test(cep);
  },
  password: (value, [target]) => {
    return value === target;
  },
  strongPass: (value, [strong, minLength]) => {
    const validate = (value = '', strong, minLength) => {
      const strongKeys = ['weak', 'normal', 'strong', 'extraStrong'];
      switch (strong) {
        case strongKeys[0]:
          return (value || '').length >= (minLength || 6);
        case strongKeys[1]:
          return (value || '').length >= (minLength || 8);
        case strongKeys[2]:
          return (value || '').length >= (minLength || 8) && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/g.test((value || ''));
        case strongKeys[3]:
          return (value || '').length >= (minLength || 8) && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[@$!%*?&]/g.test(value);
        default:
          return false;
      }
    };
    const message = (strong, minLength) => {
      const strongKeys = ['weak', 'normal', 'strong', 'extraStrong'];
      const strongMessages = {
        [strongKeys[0]]: `A senha deve ter no mínimo ${minLength || 6} caracteres`,
        [strongKeys[1]]: `A senha deve ter no mínimo ${minLength || 8} caracteres`,
        [strongKeys[2]]: `A senha deve ter no mínimo ${minLength || 8} caracteres e uma letra maiúscula e uma letra minúscula e um número`,
        [strongKeys[3]]: `A senha deve ter no mínimo ${minLength || 8} caracteres e uma letra maiúscula e uma letra minúscula e um número e um caracter especial`,
        default: 'A validação da senha falhou, verifique a chave strong'
      };
      if (strongKeys.includes(strong))
        return strongMessages[strong];
      else {
        return strongMessages.default;
      }
    };

    return validate(value, strong, minLength) || message(strong, minLength);
  },
  // required: (value) => {
  //   if (isNullOrUndefined(value) || isEmptyArray(value) || value === false) {
  //     return false;
  //   }
  //   return !!String(value).trim().length;
  // }
  'required_radio': (value) => {
    // check if exist value and is not empty
    return !(value === undefined || value === null || value === '');
  }
};

const messages = {
  document: 'O campo {field} não é um documento válido',
  cnpj: 'O campo {field} não é um CNPJ válido',
  cpf: 'O campo {field} não é um CPF válido',
  phone: 'O campo {field} não é um telefone válido',
  url: 'O campo {field} não é uma URL válida',
  accepted: 'O campo {field} precisa ser aceito',
  'not_accepted': 'O campo {field} não foi selecionado corretamente',
  cep: 'O campo {field} não é um CEP válido',
  password: 'As senhas digitadas não são iguais',
  required: 'O campo {field} é obrigatório',
  'required_radio': 'O campo {field} é obrigatório'
};

const customRules = {
  validatePassword(value) {
    const errors = [];

    const minLengthError = value.length >= 8 ? '' : '8 caracteres';
    const lowercaseError = /(?=.*[a-z])/.test(value) ? '' : 'uma letra minúscula';
    const uppercaseError = /(?=.*[A-Z])/.test(value) ? '' : 'uma letra maiúscula';
    const numberError = /(?=.*\d)/.test(value) ? '' : 'um número';
    const specialCharError = /(?=.*[@$!%*?&])/.test(value) ? '' : 'um caracter especial';

    if (minLengthError) errors.push(minLengthError);
    if (lowercaseError) errors.push(lowercaseError);
    if (uppercaseError) errors.push(uppercaseError);
    if (numberError) errors.push(numberError);
    if (specialCharError) errors.push(specialCharError);

    const hasErrors = errors.length > 0;

    return {
      valid: !hasErrors,
      errors: hasErrors
        ? `A senha deve conter no mínimo ${errors.length === 1 ? errors[0] : ''}${errors.slice(0, -1).join(', ')}${errors.length > 1 ? ` e ${errors[errors.length - 1]}` : ''}`
        : '',
    };
  },
  validateConfirmPassword(value, confirmValue) {
    const valid = value === confirmValue;

    return {
      valid,
      errors: !valid ? 'As senhas digitadas não são iguais' : '',
    };
  },
};

export { rules, customRules, messages };