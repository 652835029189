<template>
  <section :class="{ fullContentPage: !isRequiredAuth() }" class="main-view">
    <div class="container-scroller">
      <router-view name="appHeader" @collapsed="collapsed = !collapsed"></router-view>
      <div class="container-fluid page-body-wrapper px-0">
        <router-view name="appSidebar" :collapsed="collapsed"></router-view>
        <div class="main-panel">
          <div class="content-wrapper">
            <router-view name="appContent"></router-view>
          </div>
          <!-- content wrapper ends -->
          <router-view name="appFooter"></router-view>
        </div>
        <!-- main panel ends -->
      </div>
      <!-- page-body-wrapper ends -->
      <div class="message-alert">MENSAGEM DE ALERTA AQUI</div>
    </div>
  </section>
</template>

<script>
// import { getCookie } from '@/helpers/cookie'

export default {
  name: 'MainPage',
  data() {
    return {
      token: null,
      collapsed: false,
    };
  },
  watch: {
    // '$route.name': function (name) {
    //   if (name === 'home') this.$router.push({ name: 'awardedCreate' })
    // }
  },
  beforeMount() {
    // this.token = getCookie('token')
    // if (this.$route.name === 'home') this.$router.push({ name: 'awardedCreate' })
  },
  methods: {
    isRequiredAuth() {
      return this.$route.meta && this.$route.meta.requiresAuth;
    },
  },
};
</script>

<style scoped lang="scss">
.main-view {
  .message-alert {
    display: none;
    position: fixed;
    bottom: 10px;
    right: 10px;
    background-color: #9386ff;
    padding: 25px;
    border-radius: 5px;
    color: #fff;
    font-size: 0.9em;
  }
}
.fullContentPage {
  .main-panel {
    width: 100%;
    .content-wrapper {
      padding: 0 !important;
      .page-body-wrapper {
        min-height: 0 !important;
      }
    }
  }
  .container-scroller {
    .page-body-wrapper.full-page-wrapper {
      min-height: calc(100vh - 80px) !important;
    }
  }
  .navbar.fixed-top + .page-body-wrapper {
    padding-top: 10;
  }
}
</style>
