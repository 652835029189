import * as veeValidate from 'vee-validate';
import * as allRules from '@vee-validate/rules';
import { localize, loadLocaleFromURL  } from '@vee-validate/i18n';
import { rules, messages } from '@/plugins/vee-validate/rules.js';

const { configure, defineRule } = veeValidate;

const language = navigator.language.replace('-', '_');

loadLocaleFromURL (language).
  then(locale => {
    localize(language, locale);
    configure({
      generateMessage: localize({
        [language]: {
          ...locale,
          messages: {
            ...locale.messages,
            ...messages
          }
        }
      }),
      validateOnInput: true,
    });
  });
  
Object.keys(allRules).forEach(rule => {
  // defineRule(rule, allRules[rule]);
  if (typeof allRules[rule] !== 'function') {
    return;
  } else {
    defineRule(rule, allRules[rule]);
  }
});


Object.keys(rules).forEach(rule => {
  defineRule(rule, rules[rule]);
});

export default veeValidate;