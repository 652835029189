<template>
  <nav class="navbar navbar-expand-lg default-layout-navbar app-header">
    <router-link class="navbar-brand" :to="{ name: 'home' }">
      <img
        src="../../../assets/images/onlypay/logo.svg"
        height="40"
        class="d-inline-block align-top"
        alt="logo"
      />
    </router-link>
    <!-- set hamburger menu icon -->
    <button @click="$emit('collapsed')" class="btn btn-transparent py-0">
      <i class="fa fa-bars"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav ml-auto align-items-center pr-5">
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle text-black icon-allignment"
            href="javascript:void(0)"
            role="button"
            data-toggle="dropdown"
            aria-expanded="false"
            @click="onNotifications"
          >
            <i class="icon-bell" style="font-size: 2em"></i>
          </a>
        </li>

        <li class="nav-item dropdown ml-1">
          <a
            class="nav-link dropdown-toggle text-black"
            href="javascript:void(0)"
            role="button"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="icon-user" style="font-size: 2em"></i>
            <span class="availability-status online"></span>
            {{ (user.name || "").split(/(\s+)/)[0] }}
          </a>
          <div class="dropdown-menu dropdown-munu-right" style="left: -3vw">
            <router-link
              v-if="roles.includes('Administrador') || roles.includes('Operacional')"
              class="dropdown-item"
              :to="`/representantes-premiados/${this.user.id}/editar`"
            >
              <i class="mdi mdi-account mr-2 text-success"></i>
              Editar Perfil
            </router-link>
            <div
              class="dropdown-item update-password-btn"
              @click="onUpdatePassword"
            >
              <i class="mdi mdi-lock text-info"></i>
              Alterar Senha
            </div>
            <div class="dropdown-item exit-btn" @click="logOut">
              <i class="mdi mdi-logout text-danger"></i>
              Sair
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
  <base-modal size="md" v-model="showModalNotifications">
    <notifications-component :userId="user.id" />
  </base-modal>
  <base-modal size="md" v-model="showModalUpdatePassword">
    <form-update-password :userId="user.id" @hide-modal="onHideModal" />
  </base-modal>
</template>

<script lang="js">
// eslint-disable-next-line
import JQuery from 'jquery'
import { signOut, getUserDetails } from '@/helpers/user';
import FormUpdatePassword from './FormUpdatePassword/FormUpdatePassword.vue';
import NotificationsComponent from './NotificationsComponent/NotificationsComponent.vue';

const $ = JQuery;
export default {
  name: 'AppHeader',
  emits: ['collapsed'],
  components: {
    'form-update-password': FormUpdatePassword,
    'notifications-component': NotificationsComponent
  },
  data () {
    return {
      user: {},
      showModalUpdatePassword: false,
      showModalNotifications: false,
      isShowLoading: false,
      roles: []
    };
  },
  mounted () {
    this.user = JSON.parse(getUserDetails());
    this.getRoles();
  },
  methods: {
    getRoles: async function () {
      this.isShowLoading = true;
      this.$store.commit('setUserId', this.user.id);

      await this.$http
        .get(
          `/user/getById?id=${this.user.id}`
        )
        .then(({ data }) => {
          this.roles = data.roles.map(e => e.name);
        })
        .catch((e) => {
          return e;
        });
      this.isShowLoading = false;
    },
    onHideModal: function () {
      this.showModalUpdatePassword = false;
    },
    collapedSidebar: function () {
      $('body').toggleClass('sidebar-icon-only');
    },
    collapedMobileSidebar: function () {
      $('#sidebar').toggleClass('active');
    },
    logOut: function () {
      signOut();
    },
    onUpdatePassword () {
      this.showModalUpdatePassword = true;
    },
    onNotifications () {
      this.showModalNotifications = true;
    }
  }
};
</script>

<style scoped lang="scss">
.app-header {
  .logo {
    height: 48px !important;
  }
  .logo-mini {
    width: 70px !important;
    margin-left: 20px !important;
  }

  .availability-status {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    border: 2px solid #ffffff;
    bottom: 8px;
    left: calc(2rem + 3px);
    background-color: #1bcfb4;
  }

  .icon-allignment {
    margin-top: 1px;
  }

  .update-password-btn {
    cursor: pointer;

    i {
      font-size: 1.35rem !important;
      margin-right: 10.5px;
    }
  }
  .exit-btn {
    cursor: pointer;

    i {
      margin-left: 2px;
    }
  }
}
</style>
