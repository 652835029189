<template>
  <div class="base_step">
    <ul class="base_step_steps">
      <li class="base_step_step"
        :class="{
          'active': isMobile ? currentStep === index : currentStep >= index,
          'vgw-mobile': isMobile,
        }"
        :style="componentStepStyle"
        v-for="(step, index) of steps" :key="index">
        <span class="base_step_step_line" :class="{'vgw-mobile': isMobile}"></span>
        <span class="base_step_step_label">{{step.label}}</span>
        <span class="base_step_step_indicator"></span>
      </li>
    </ul>
    <span
      class="base_step_arrow"
      :style="{ left: arrowPosition }">
    </span>
    <!-- <div ref="component-body" class="base_step_body col-12" :class="{'vgw-mobile': isMobile}"> -->
    <component
      :is="typeComponent"
      v-on="listeners"
      :class="classComponent"
      :key="currentSlot"
      ref="component-body" class="base_step_body col-12">
      <!-- <div :key="currentSlot"> -->
      <slot :name="currentSlot"></slot>
      <!-- </div> -->
      <div class="base_step_body_actions clearfix col-12" :class="{'row mx-0': isMobile}">
        <button
          v-if="backEnabled"
          id="baseStepBackButton"
          ref="backButton"
          type="button"
          class="btn btn-outline-danger float-left"
          :class="{'col px-1 mr-1': isMobile}"
          @click.prevent="buttonCliked = 'back'; goBack()">
          <i class="fa fa-arrow-left"></i>
          <span>{{previousStepLabel}}</span>
        </button>
        <button
          v-if="currentStep != steps.length - 1" class="btn btn-outline-primary float-right"
          id="baseStepNextButton"
          :type="isForm ? 'submit' : 'button'"
          ref="nextButton"
          :class="{'disabled': options[currentStep].nextDisabled, 'col px-1 mr-1': isMobile}"
          @click="isForm ? buttonCliked = 'next' : goNext()">
          <span>{{nextStepLabel}}</span>
          <i class="fa fa-arrow-right"></i>
        </button>
        <button
          v-else class="btn btn-outline-success float-right final-step"
          id="baseStepFinishButton"
          :type="isForm ? 'submit' : 'button'"
          ref="finishButton"
          :class="{'disabled': options[currentStep].nextDisabled, 'col px-1 ml-1': isMobile}"
          @click="isForm ? buttonCliked = 'finish' : goNext()">
          <i class="fa fa-save"></i>
          {{finalStepLabel}}
        </button>
      </div>
    </component>
  </div>
</template>

<script>
export default {
  name: 'BaseStep',
  data () {
    return {
      currentStep: 0,
      isMounted: false,
      resizer: null,
      isMobile: false,
      options: [],
      buttonCliked: null,
      listeners: {},
      isForm: false
    };
  },
  props: {
    steps: {},
    previousStepLabel: {
      type: String,
      default: 'Voltar'
    },
    nextStepLabel: {
      type: String,
      default: 'Próximo'
    },
    finalStepLabel: {
      type: String,
      default: 'Salvar'
    },
    onNext: {
      type: Function,
      default: () => { return true; }
    },
    onBack: {
      type: Function,
      default: () => { return true; }
    },
    typeComponent: {
      type: String,
      default: 'div'
    },
    classComponent: {
      type: String,
      default: ''
    },
    setCurrentStep: {
      type: Number,
      default: 0
    }
  },
  watch: {
    steps: {
      handler () {
        this.parseOptions();
      },
      immediate: true
    },
    setCurrentStep: {
      handler (value) {
        this.currentStep = value;
      },
      immediate: true
    },
    currentStep: {
      handler (value) {
        // this.$emit('update:currentStep', value)
        this.$emit('currentStep', value);
      },
      immediate: true
    }
  },
  computed: {
    componentStepStyle () {
      if (this.isMobile) {
        return {
          width: '100%'
        };
      }

      return {
        width: `${100 / this.steps.length}%`
      };
    },
    mobileArrowPosition () {
      return 'calc(50% - 14px)';
    },
    arrowPosition () {
      if (this.isMobile) {
        return this.mobileArrowPosition;
      }
      const stepSize = 100 / this.steps.length;
      const currentStepStart = stepSize * this.currentStep;
      const currentStepMiddle = currentStepStart + (stepSize / 2);
      if (this.steps.length === 1) { return `calc(${  currentStepMiddle  }%)`; } else { return `calc(${  currentStepMiddle  }% - 14px)`; }
    },
    currentSlot () {
      return this.steps[this.currentStep].slot;
    },
    backEnabled () {
      return this.currentStep !== 0;
    }
  },
  methods: {
    async goNext (skipFunction) {
      if (!skipFunction && typeof this.onNext === 'function') {
        if (!await this.onNext(this.currentStep)) {
          // returned false. don't do anything
          return;
        }
      }
      if (this.currentStep < this.steps.length - 1) {
        this.currentStep++;
      }
    },
    async goBack (skipFunction) {
      // check key pressed is enter and click by ref next button
      // console.log('passsando por aqui', this.buttonCliked, this.buttonCliked !== 'back')
      // if (this.buttonCliked !== 'back') {
      //   console.log('aqui tambem')
      //   if (this.isForm) this.buttonCliked = 'next'
      //   this.$refs.nextButton.click()
      //   return
      // }
      if (!skipFunction && typeof this.onBack === 'function') {
        if (!await this.onBack(this.currentStep)) {
          // returned false. don't do anything
          return;
        }
      }
      if (this.currentStep > 0) {
        this.currentStep--;
      }
    },
    goTo (step) {
      if (Number.isInteger(step) &&
        step < this.steps.length &&
        step >= 0) {
        this.currentStep = step;
      }
    },
    parseOptions () {
      this.options = [];
      for (let i = 0; i < this.steps.length; i++) {
        this.options.push(this.steps[i].options ? this.steps[i].options : {});
      }
    },
    handleResize () {
      // console.log('handle resize')
      if (this.resizer) {
        clearTimeout(this.resizer);
      }
      this.resizer = setTimeout(() => {
        // console.log('resizing...')
        this.isMobile = this.$refs['component-body'].clientWidth < 620;
      }, 100);
    },
    onSubmit () {
      const action = this.buttonCliked;
      if (action === 'next' || action === 'finish') {
        this.goNext();
      } else {
        this.goBack();
      }
      this.buttonCliked = null;
    }
  },
  mounted () {
    this.isMobile = this.$refs['component-body'].clientWidth < 620;
    window.addEventListener('resize', this.handleResize);
    // access ref by parent component
    // this.$parent.$refs.baseStep = this;
    this.$refs.baseStep = this;
    if ((/form/).test(this.typeComponent)) {
      this.listeners = {
        onSubmit: this.onSubmit
      };
    } else this.listeners = {};
  },
  beforeMount () {
    this.isForm = /form/.test(this.typeComponent);
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style lang="scss" scoped>
.base_step{
  /* Header Steps
  *******************************/
  position: relative;
  width:  100%;

  /* Utilities
  *******************************/

  .clearfix::after {
    display: block;
    content: "";
    clear: both;
  }

  &_steps{
    list-style-type:  none;
    text-align: justify;
    -ms-text-justify: distribute-all-lines;
    text-justify: distribute-all-lines;
    padding:  0;
    height:  70px;
    position:  relative;
  }

  .stretch {
    width: 100%;
    display: inline-block;
    font-size: 0;
    line-height: 0
  }

  &_step{
    height: 70px;
    vertical-align: bottom;
    display: inline-block;
    text-align: center;
    position:  relative;
  }

  &_step:not(:first-child) &_step_line{
    position: absolute;
    width:  100%;
    left:  -50%;
    bottom:  10px;
    height:  3px;
    background-color: #b9c7d2;
  }

  &_step_indicator {
    box-sizing: content-box;
    display:  block;
    width:  16px;
    height:  16px;
    background-color: #fff;
    border: 1px solid #51abe4;
    border-radius: 50%;
    // border: 3px solid #fff;
    position:  absolute;
    left:  50%;
    margin-left:  -10px;
    bottom:  2px;
    z-index: 1;
  }

  &_step.active &_step_indicator{
    background-color: #1eff00;
    border: 1px solid var(--gray);
  }

  &_step.active:not(:first-child) &_step_line{
    background-color: #1eff00; /* green */
    // border: 1px solid var(--gray);
  }

  &_step_label{
    color:  #98a4af;
    font-weight: bold;
  }

  /* base_step body
  *******************************/
  &_body{
    margin-top:  30px;
    min-height:  400px;
    // margin-left:  50px;
    // margin-right:  50px;
    // border:  1px solid #aebac4;
    // background-color: #fff;
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    border-radius: 5px;
    padding-bottom: 50px;
  }

  &_body_step{
    padding:  16px;
  }

  // &_arrow{
  //   position:  absolute;
  //   display: block;
  //   width:  30px;
  //   height:  30px;
  //   border:  1px solid #aebac4;
  //   top:  85px; /* height of step + body margin -15 */
  //   border-top-right-radius: 5px;
  //   background-color: #fff;
  //   border-left: none;
  //   border-bottom:  none;
  //   transform: rotate(-45deg);
  //   z-index: 2;
  //   -webkit-transition: left 0.3s;
  //   -o-transition: left 0.3s;
  //   transition: left 0.3s;
  // }

  /* base-step body
  *******************************/
  &_body_actions {
    position:  absolute;
    bottom:  0px;
    left:  0px;
    // height:  50px;
    // width:  100%;
    // border-top:  1px solid #aebac4;
    // background-color: #b9c7d2;
  }

  &_body_actions a{
    width:  120px;
    height:  100%;
    display: block;
    background-color: #51abe4;
    color:  white;
    font-weight: bold;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    -webkit-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }

  &_body_actions a.disabled{
    cursor: not-allowed;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: 0.5;
  }

  &_body_actions a>.vgw-icon, &_body_actions a>span{
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }

  &_body_actions .vgw-icon::after{
    position: absolute;
    content: "";
    transform: rotate(-45deg);
    width: 8px;
    height: 8px;
    top: 50%;
    margin-top: -5px;
  }

  .vgw-icon.vgw-next::after{
    border-bottom: 2px solid white;
    border-right: 2px solid white;
  }

  .vgw-icon.vgw-prev::after{
    border-top: 2px solid white;
    border-left: 2px solid white;
    left: -10px;
  }

  &_body_actions a:hover{
    background-color: #357fae;
  }

  &_body_actions a.final-step{
    background-color: #1eff00;
  }

  /* mobile */
  &_body.vgw-mobile{
    margin-left: 10px;
    margin-right: 10px;
  }
  &_step.vgw-mobile{
    display: none;
  }
  &_step &_step_line.vgw-mobile{
    display: none;
  }
  &_step.active.vgw-mobile{
    display: inline-block;
  }
}
</style>
