export const namespaced = true;

export const SelectedTypeEnum = {
  DOCUMENT: 1,
  PERSON_ID: 2,
  ACCOUNT_ID: 3,
  CARD_ID: 4,
  CARD_NUMBER: 5
};

const defaultValues = {
  searchData: '',
  selectedType: SelectedTypeEnum.DOCUMENT,
  cardData: [],
};

export const state = {
  cardListfilters: defaultValues,
};

export const mutations = {
  setSearchData(state, searchData) {
    state.cardListfilters.searchData = searchData;
  },
  setSelectedType(state, selectedType) {
    state.cardListfilters.selectedType = selectedType;
  },
  setCardData(state, data) {
    state.cardListfilters.cardData = data;
  },
  resetValues(state) {
    state.cardListfilters = defaultValues;
  },
};
