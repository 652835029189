<template>
  <validation
    :messageError="!!errorMessage ? ($attrs.messageError || messageError || errorMessage ) : ''"
    v-bind="$attrs"
    id="base-money">
    <money3
      :id="'inputMoney' + dynamicId"
      type="text"
      v-bind="attrs"
      v-on="listeners"
      v-model.number="model"
      class="form-control"/>
  </validation>
</template>

<script>
/* eslint-disable */
import Validation from './Validation.vue';
import { useField, useForm } from 'vee-validate';
import { Money3Component } from 'v-money3';
export default {
  name: 'BaseMoney',
  inheritAttrs: false,
  data () {
    return {
      dynamicId: null,
      model: 0,
      config: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false
      }
    };
  },
  components: {
    Validation,
    money3: Money3Component
  },
  props: {
    modelValue: {
      default: 0
    },
    decimal: {
      type: String,
      default: ','
    },
    thousands: {
      type: String,
      default: '.'
    },
    prefix: {
      type: String,
      default: 'R$ '
    },
    suffix: {
      type: String,
      default: ''
    },
    precision: {
      type: Number,
      default: 2
    },
    masked: {
      type: Boolean,
      default: false
    },
    messageError: {
      type: String,
      default: ''
    }
  },
  computed: {
    listeners () {
      return {
        // TODO: ajustar listeners
        // ...this.$listeners,
        input: this.onInput,
        blur: this.handleBlur,
        ...this.attrs
      };
    },
    attrs () {
      /* eslint-disable no-unused-vars */
      const {
        validate,
        class: _class,
        style,
        ...attrs
      } = this.$attrs;
      
      return {...attrs, ...this.config};
    }
  },
  watch: {
    modelValue (value) {
      this.model = value;
    }
  },
  methods: {
    onInput (event) {
      this.handleChange(event);
      this.$emit('update:modelValue', (event && event.target && event.target.value) || null);
    },
    blockNonNumericChars () {
      const input = document.getElementById(`inputMoney${  this.dynamicId}`);
      if (!input) return false;
      input.addEventListener('keypress', (e) => {
        if (e.key.match(/[^0-9]/g)) {
          e.preventDefault();
        }
      });
    }
  },
  setup (props, {attrs}) {
    const {
      errorMessage,
      handleBlur,
      handleChange
    } = useField((attrs.fieldName || attrs.name || attrs.label), attrs.validate, {
      initialValue: props.modelValue
    });

    const form = useForm();

    form.setValues((attrs.fieldName || attrs.name || attrs.label), props.modelValue || null);
    
    return {
      errorMessage,
      handleBlur,
      handleChange
    };
  },
  beforeMount () {
    this.dynamicId = Math.floor(Math.random() * 1000000);
    this.config = {
      decimal: this.decimal,
      thousands: this.thousands,
      prefix: this.prefix,
      suffix: this.suffix,
      precision: this.precision,
      masked: this.masked
    };
    this.model = this.modelValue;
  },
  mounted () {
    this.blockNonNumericChars();
  }
};
</script>

<style>

</style>
