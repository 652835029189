<template>
  <div
    class="form-group"
    :id="dynamicId">
    <label :for="$attrs.id" v-if="label">
      {{label}}
      <span class="text-danger"></span>
    </label>
    <!-- <input
      v-bind="$attrs"
      v-on="listeners"
      :value="value"
      class="form-control"/> -->
    <textarea
      v-bind="$attrs"
      v-on="listeners"
      :value="value"
      class="form-control" />
    <span
      class="invalid-feedback">
      <strong>{{error}}</strong>
    </span>
  </div>
</template>

<script>
// import * as validation from '@/helpers/validation'
export default {
  name: 'BaseTextarea',
  inheritAttrs: false,
  data () {
    return {
      error: null,
      dynamicId: null,
      fieldError: {
        isValid: true,
        message: ''
      }
    };
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    messageError: {
      type: String,
      default: null
    },
    value: {
      default: null
    }
  },
  computed: {
    listeners () {
      return {
        // ...this.$listeners,
        input: this.onInput
      };
    }
  },
  watch: {},
  methods: {
    generateDynamicId () {
      return Math.random().toString(36).substr(2, 9);
    },
    onInput (event) {
      this.$emit('input', event.target.value);
    }
  },
  beforeMount () {
    this.dynamicId = `base__textarea__${  this.generateDynamicId()}`;
  }
};
</script>

<style>

</style>
