<template>
  <!-- base form -->
  <Form
    :ref="'baseForm' + dynamicId"
    @submit="onSubmit"
    tag="form"
    class="baseForm"
    :id="dynamicId"
    v-bind="$attrs">
      <slot></slot>
  </Form>
</template>

<script setup>
// eslint-disable-next-line no-unused-vars
import { useForm } from 'vee-validate';
// eslint-disable-next-line no-unused-vars
import { ref, defineComponent, defineProps, defineEmits, onMounted } from 'vue';

defineComponent({
  name: 'BaseForm',
  inheritAttrs: false
});

const dynamicId = ref(null);

const props = defineProps({
  id: {
    type: String,
    default: null
  }
});

function generateDynamicId () {
  if (props.id) {
    dynamicId.value = props.id;
  } else {
    dynamicId.value = `base__form__${  Math.random().toString(36).substr(2, 9)}`;
  }
}

const emit = defineEmits(['onSubmit']);

function onSubmit (event) {
  emit('onSubmit', event);
}

onMounted(() => {
  generateDynamicId();
});

</script>

<style lang="scss">
  .baseForm {
    input, select{
      height: 44px;
    }
    input.v-money {
      padding: .875rem 1.375rem;
    }
  }
</style>
