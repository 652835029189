import routes from '@/router/routes';
import { createRouter, createWebHistory } from 'vue-router';
import { getCookie } from '@/helpers/cookie';

const history = createWebHistory();

const router = createRouter({
  history,
  linkActiveClass: 'active',
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes,
});

router.beforeEach(async (to, from, next) => {
  setTitleThisRoute(to);
  // console.table(to)
  const token = getCookie('token');
  if (to.meta.requiresAuth) {
    if (!token) {
      document.cookie.split(';').forEach((c) => {
        // document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
        document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=Thu, 01 Jan 1970 00:00:00 GMT ;path=/');
      });
      localStorage.clear();
      next({ name: 'login' });
    } else {
      // TODO: check if roles is permitted
      // console.log(await checkRules(to.meta.rules && to.meta.rules.name))
      next();
    }
  } else {
    if (token != null) next({ name: 'home' });
    else next();
  }
});

function setTitleThisRoute (to) {
  // set default values same title, icon and breadcrumb...
  window.document.title = `${to.meta.title  } - OnlyPay` || 'OnlyPay - Soluções de pagamento';
  // window.document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description || 'OnlyPay - Soluções de pagamento')
}

export default router;
