/* eslint-disable */
export function setCookie (key, value, hours) {
  var date = new Date()
  // Default at 365 days.
  hours = hours || 1
  // Get unix milliseconds at current time plus number of hours
  date.setTime(date + (hours * 60 * 60 * 1000)) // 24 * 60 * 60 * 1000
  window.document.cookie = key + '=' + value + '; expires=' + date.toGMTString() + '; path=/'
}

export function getCookie (name) {
  var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'))
  return match ? match[1] : null
}

export function escape (s) {
  return s.replace(/([.*+?\^${}()|\[\]\/\\])/g, '\\$1')
}

export function removeCookie (key) {
  var date = new Date()
  // Get unix milliseconds at current time plus number of hours
  date.setTime(date - (24 * 60 * 60 * 1000)) // 24 * 60 * 60 * 1000
  window.document.cookie = key + '=null; expires=' + date.toGMTString() + '; path=/'
}
