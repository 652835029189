<template>
  <div>
    <base-loading :show="loading" />
    <div class="row px-0 mx-0">
      <h3 class="mb-5 col-12" style="color: rgb(83, 83, 92)">Notificações</h3>

      <div class="mb-5 col-12">
        <div
          v-if="!notificationData.length && !loading"
          class="d-flex align-items-center"
        >
          <i
            class="mdi mdi-message mr-2"
            style="font-size: 1em; margin-top: 2px"
          ></i>
          <p class="my-0 mr-0 ml-1">{{ "Não notificações neste momento." }}</p>
        </div>

        <div
          v-else-if="notificationData.length && !loading"
          class="d-flex flex-column"
        >
          <div
            :class="{
              'notifications-card-container-read': item.read,
              'notifications-card-container': !item.read,
            }"
            class="mb-3"
            v-for="(item, index) in notificationData"
            :key="index"
          >
            <div
              :class="{
                'notifications-card-header-read': item.read,
                'notifications-card-header': !item.read,
              }"
            >
              <h6 class="m-0">
                {{ item.title }}
              </h6>

              <i
                class="mdi mdi-delete"
                style="font-size: 1.3em"
                @click="onDelete(item)"
              ></i>
            </div>
            <div
              class="d-flex align-items-center py-3 notifications-card-content"
            >
              <p class="my-0 mr-0">
                {{ item.message }}
              </p>
              <i
                v-if="!item.read && !item.link"
                class="mdi mdi-checkbox-blank-circle-outline mr-2 ml-2 text-success"
                style="font-size: 1.3em; cursor: pointer"
                @click="onRead(item)"
              ></i>
              <i
                v-else-if="item.read && !item.link"
                class="mdi mdi-checkbox-marked-circle mr-2 ml-2 text-success"
                style="font-size: 1.3em"
              ></i>

              <i
                v-if="item.link"
                class="mdi mdi-arrow-right-bold-circle mr-2 ml-2 text-success"
                style="font-size: 1.3em; cursor: pointer"
                @click="openLink(item)"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// eslint-disable-next-line no-unused-vars
import { ref, defineProps, onBeforeMount } from 'vue';
import $http from '@/plugins/axios';

// const emit = defineEmits(['hide-modal']);
const loading = ref(false);
const notificationData = ref([]);
const selectedItem = ref([]);

// const readIcon = ref(false);

const props = defineProps({
  userId: {
    type: String,
    default: null,
    required: true,
  },
});

onBeforeMount(() => {
  getNotifications();
});

const onRead = (item) => {
  item.read = true;
  selectedItem.value = item;

  updateNotification();
};

const openLink = (item) => {
  item.read = true;
  selectedItem.value = item;
  updateNotification();

  const url = item.link;
  window.open(url, '_blank');
};

const onDelete = (item) => {
  deleteNotification(item.id);
};

async function getNotifications() {
  loading.value = true;

  await $http
    .get(`/Notification/GetByUser?userId=${props.userId}`)
    .then((res) => {
      notificationData.value = res.data;
    })
    .catch((err) => {
      return err;
    })
    .finally(() => {
      loading.value = false;
    });
}

async function updateNotification() {
  loading.value = true;

  await $http
    .put('/Notification', selectedItem.value)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    })
    .finally(() => {
      loading.value = false;
    });
}

async function deleteNotification(notificationId) {
  await $http
    .delete(`/Notification?Id=${notificationId}`)
    .then(() => {
      getNotifications();
    })
    .catch((err) => {
      return err;
    });
}
</script>

<style lang="scss">
.notifications-card-container {
  border: 1px solid #198ae3;
  border-radius: 10px;
}

.notifications-card-container-read {
  border: 1px solid #62b3f1;
  border-radius: 10px;

  p {
    color: #616d79;
  }
}

.notifications-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #198ae3;
  color: #fff;
  padding: 0.7rem;
  border-top-left-radius: 7.5px;
  border-top-right-radius: 7.5px;
  width: 100%;

  i:hover {
    color: black;
    cursor: pointer;
  }
}

.notifications-card-header-read {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #62b3f1;
  color: #fff;
  padding: 0.7rem;
  border-top-left-radius: 7.5px;
  border-top-right-radius: 7.5px;
  width: 100%;

  i:hover {
    color: black;
    cursor: pointer;
  }
}

.notifications-card-content {
  padding-left: 0.7rem;
}
</style>
