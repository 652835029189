<template>
  <section class="app-footer">
    <footer class="footer">
      <div class="container-fluid clearfix">
        <span
          class="text-muted d-block text-center text-sm-left d-sm-inline-block"
          >Copyright © 2018
          <a href="https://www.onlypay.com.br" target="_blank">OnlyPay</a>.
          Todos os direitos reservados.</span
        >
        <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"
          >V{{ this.appVersion }} - Powered by OnlyPay</span
        >
      </div>
    </footer>
  </section>
</template>

<script lang="js">
export default {
  name: 'AppFooter',
  data () {
    return {
      appVersion: '',
    };
  },
  mounted () {
    this.getAppVersion();
  },
  methods: {
    getAppVersion () {
      fetch('appVersion.json')
        .then(res => res.json())
        .then(data => this.appVersion = data.version)
        .catch(err => {
          return err;
        });
    }
  }
};
</script>

<style scoped lang="scss">
.app-footer {
}
</style>
