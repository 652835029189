<template>
  <div
    class="modal fade"
    :id="id"
    tabindex="-1"
    role="dialog"
    :aria-labelledby="id + '-component'"
    aria-hidden="true"
    style="background-color: rgba(0, 0, 0, 0.4);">
    <div
      :class="{
        'modal-dialog modal-dialog-centered modal-dialog-scrollable': true,
        ['modal-'+ size ]: size
      }"
      role="document">
      <div class="modal-content bg-white">
        <div class="modal-header">
          <span class="modal-title" :id="id + '-component'" v-html="title"></span>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="hideModal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" v-if="modelValue">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// eslint-disable-next-line no-unused-vars
import { ref, defineEmits, defineProps, watch, onBeforeMount, inject, defineComponent, onMounted } from 'vue';
defineComponent({
  name: 'BaseModal',
});

const emit = defineEmits(['update:modelValue']);
const id = ref('');
const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  modelValue: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: 'lg',
    validator: function (value) {
      return ['sm', 'md', 'lg', 'xl'].indexOf(value) !== -1;
    }
  }
});
watch(() => props.modelValue, (value) => {
  if (value) showModal();
  else hideModal();
}, { immediate: true });
function createDynamicIdModal() {
  id.value = `modal-${Math.random().toString(36).substr(2, 9)}`;
}
function showModal() {
  const modal = document.getElementById(id.value);
  if (!modal) {
    if (props.modelValue) return;
    emit('update:modelValue', false);
    return;
  }
  modal.classList.add('show');
  modal.style.display = 'block';
  modal.setAttribute('modal-open', '');
}
function hideModal() {
  const modal = document.getElementById(id.value);
  if (!modal) {
    emit('update:modelValue', false);
    return;
  }
  modal.classList.remove('show');
  modal.style.display = 'none';
  modal.removeAttribute('modal-open', '');
  emit('update:modelValue', false);
}
onBeforeMount(() => {
  createDynamicIdModal();
});
onMounted(() => {
  if (props.modelValue)
    showModal();
});
</script>

<style lang="scss">
</style>
