import VueTheMask from 'vue-the-mask';

// config tokens 
VueTheMask.setMask = {
  tokens: {
    '#': {pattern: /\d/},
    'X': {pattern: /[0-9a-zA-Z]/},
    'S': {pattern: /[a-zA-Z]/},
    'A': {pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase()},
    'a': {pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase()},
    '!': {escape: true}
  }
};

export default VueTheMask;