<template>
  <Validation
    class="form-group"
    :rules="validate"
    :name="field"
    tag="div"
    v-slot="{ errors }"
    :id="dynamicId">
    <div
      class="form-check form-check-inline d-flex"
      :class="{
        'mb-0': errors[0]
      }">
      <input
        v-bind="$attrs"
        v-on="listeners"
        :value="value"
        type="checkbox"
        class="form-check-input"/>
      <label :for="$attrs.id" v-if="label" class="form-check-label mx-0">
        {{label}}
      <span class="text-danger" v-if="/required/.test(validate)">*</span>
      </label>
    </div>
    <span
      class="invalid-feedback" :class="{'d-block': errors[0]}">
      <strong>{{messageError || errors[0]}}</strong>
    </span>
  </Validation>
</template>

<script>
import Validation from './Validation.vue';
export default {
  name: 'BaseCheckbox',
  inheritAttrs: false,
  data () {
    return {
      dynamicId: null
    };
  },
  components: {
    Validation
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    messageError: {
      type: String,
      default: null
    },
    value: {
      default: false
    },
    inline: {
      type: Boolean,
      default: true
    },
    field: {
      type: String,
      default: null
    },
    validate: {
      type: String,
      default: null
    }
  },
  computed: {
    listeners () {
      return {
        // ...this.$listeners,
        input: this.onInput
      };
    }
  },
  watch: {},
  methods: {
    generateDynamicId () {
      return Math.random().toString(36).substr(2, 9);
    },
    onInput (event) {
      this.$emit('input', event.target.checked);
    },
    getOptionObject (option, value) {
      // different from object return errors throws
      if (typeof value !== 'object') throw Error('Value must be an object');
      // create object with name of input and value of input
      const object = {};
      option.forEach((item) => {
        object[item.name] = value[item.name || item.value] || false;
      });
      return object;
    }
  },
  beforeMount () {
    this.dynamicId = `base__input__${  this.generateDynamicId()}`;
  }
};
</script>

<style>

</style>
