<template>
  <div
    class="form-group"
    v-bind="attrs"
    :id="dynamicId">
    <label v-if="label">
      {{label}}
      <span
        v-if="/required/.test(JSON.stringify(validate))"
        class="text-danger">
        *
      </span>
    </label>
    <slot></slot>
    <!-- set obs description -->
    <small v-if="obs && !messageError" class="form-text text-primary text-right"><span>*</span>Obs: <span v-html="obs" /></small>
    <span
      class="invalid-feedback" :class="{'d-block': messageError}">
      <strong>{{messageError}}</strong>
    </span>
  </div>
</template>

<script>
export default {
  name: 'validationInput',
  inheritAttrs: false,
  data () {
    return {
      dynamicId: null,
      errors: []
    };
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    messageError: {
      type: String,
      default: null
    },
    fieldName: {
      type: String,
      default: null,
      description: 'Name your field to use in validation'
    },
    validate: {
      type: [String, Object],
      default: null,
      description: 'Validation rules'
    },
    obs: {
      type: String,
      default: null,
      description: 'Observation'
    },
    id: {
      type: String,
      default: null,
      required: true,
      description: 'Id of input'
    }
  },
  computed: {
    attrs () {
      /* eslint-disable no-unused-vars */
      const {
        validate,
        messageError,
        fieldName,
        label,
        obs,
        id,
        name,
        placeholder,
        modelModifiers,
        rules,
        value,
        ...attrs
      } = this.$attrs;
      return attrs;
    }
  },
  methods: {
    generateDynamicId () {
      return Math.random().toString(36).substr(2, 9);
    },
  },
  beforeMount () {
    this.dynamicId = this.id + this.generateDynamicId();
  }
};
</script>

<style>

</style>
